import React from "react";
import { ParkRide } from "../types";
import { Sparkle, XSquare } from "@phosphor-icons/react";
import FilmCard from "./FilmCard";

const DetailPanel = (props: { ride?: ParkRide; onClose: () => void }) => {
  const windowWidth = window.innerWidth;
  const { ride, onClose } = props;
  const heightRequirementText = ride?.heightRequirement
    ? `${ride.heightRequirement}"+`
    : "Any Height";

  return (
    <div
      className="bg-gray-950 w-screen sm:w-96 sm:h-[100dvh] max-sm:h-[50dvh] overflow-y-scroll block absolute top-0 z-10 transition-all drop-shadow-2xl"
      style={{
        left: windowWidth >= 640 ? (ride ? "0" : "-24rem") : "auto",
        top: windowWidth < 640 ? (ride ? "50vh" : "100vh") : "0",
      }}
    >
      <button onClick={onClose} className="absolute right-5 top-5 z-10">
        <XSquare size={24} />
      </button>
      <div className="sm:aspect-square w-full max-sm:h-32 relative z-0 -mb-14">
        <img
          src={ride?.background}
          className="h-full w-full object-cover absolute top-0"
          alt={`${ride?.title} background`}
        />
        <div className="bg-gradient-to-b from-transparent to-gray-950 aspect-[2] w-full absolute bottom-0" />
      </div>
      <div className="relative flex gap-1 flex-col items-center w-full px-5 z-10">
        <h1 className="font-bold text-lg tracking-[0.25em] uppercase text-center leading-6 mx-2">
          {ride?.title}
        </h1>
        <h2 className="text-xs text-white/75 mb-1">
          {ride?.type}&nbsp;&nbsp;{ride?.year}&nbsp;&nbsp;
          {heightRequirementText}
        </h2>
        <p className="text-sm mb-6">{ride?.description}</p>
        <h3 className="text-xs text-white/75 font-bold tracking-[0.5em] uppercase">
          Related Films
        </h3>
        <p className="text-[10px] mr-2 text-white/50 mb-6 flex items-center">
          <Sparkle size={12} className="mr-1" /> Primary Ride Inspiration
        </p>
        {ride?.relatedFilms.map((film) => (
          <FilmCard
            key={`${film.title}-${film.year}-related-film-card`}
            film={film}
          />
        ))}
        {!ride?.relatedFilms.length && (
          <p className="text-white/50 uppercase text-xs">
            No related films found
          </p>
        )}
      </div>
    </div>
  );
};

export default DetailPanel;
