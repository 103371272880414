import React, { useEffect, useMemo, useRef } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import {
  MapContainer,
  // ImageOverlay,
  Marker,
  useMapEvents,
  ZoomControl,
  TileLayer,
} from "react-leaflet";
import { DivIcon, Map as LeafletMap } from "leaflet";

import { Park } from "../types";
import { markerColorMap, markerIconMap } from "../consts";

const Coordinates = () => {
  useMapEvents({
    click(e) {
      console.log([e.latlng.lat, e.latlng.lng]);
    },
  });
  return <></>;
};

const Map = (props: {
  park: Park;
  selectedRide?: string;
  selectRide: (name: string) => void;
}) => {
  const windowWidth = window.innerWidth;
  const { park, selectedRide, selectRide } = props;
  const map = useRef<LeafletMap>(null);

  const markers = useMemo(() => {
    return park.rides.map((ride) => {
      const isSelected = selectedRide === ride.title;
      return (
        <Marker
          key={ride.title}
          icon={
            new DivIcon({
              html: renderToStaticMarkup(
                <div
                  className="rounded-full flex items-center justify-center"
                  style={{
                    backgroundColor: markerColorMap.get(ride.type),
                    width: isSelected ? 28 : 18,
                    height: isSelected ? 28 : 18,
                  }}
                >
                  {markerIconMap.get(ride.type)?.(isSelected)}
                </div>,
              ),
              iconSize: isSelected ? [28, 28] : [18, 18],
              iconAnchor: isSelected ? [14, 14] : [9, 9],
            })
          }
          opacity={isSelected ? 1 : selectedRide ? 0.6 : 1}
          position={ride.geo}
          eventHandlers={{
            click: () => {
              selectRide(ride.title);
              map.current?.flyTo(
                [
                  ride.geo[0] - (windowWidth < 640 ? 0.0004 : 0),
                  ride.geo[1] - (windowWidth < 640 ? 0 : 0.0005),
                ],
                19,
              );
            },
          }}
        />
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [park.rides, selectRide, selectedRide]);

  useEffect(() => {
    if (map.current) {
      map.current.flyTo(park.center, 17);
    }
  }, [park, map]);

  return (
    <MapContainer
      ref={map}
      className="w-screen h-[100dvh] z-0"
      center={park.center}
      zoom={17}
      minZoom={16}
      maxZoom={20}
      attributionControl={false}
      zoomControl={false}
    >
      <ZoomControl position="topright" />
      {/* <ImageOverlay
        url={park.map.image}
        zIndex={-1}
        bounds={[[0, 0], park.map.dimensions]}
        className="absolute inset-0"
      /> */}
      {markers}
      <TileLayer
        maxZoom={20}
        minZoom={16}
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
        className="grayscale"
      />
      <Coordinates />
    </MapContainer>
  );
};

export default Map;
