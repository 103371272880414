import { Park, Resort } from "../types";
import {
  ALICEINWONDERLAND,
  ALIENPIZZAPLANET,
  ASTROBLASTERS,
  AUTOPIA,
  AVENGERSHQ,
  BIGTHUNDERMOUNTAIN,
  CASEYJRCIRCUSTRAIN,
  CHIPNDALESGADGETCOASTER,
  DAVYCROCKETTSCANOESRIDE,
  DONALDSDUCKPOND,
  DUMBOTHEFLYINGELEPHANT,
  ENCHANTEDTIKIROOM,
  FINDINGNEMO,
  GOLDENZEPHYR,
  GOOFYSHOWTOYARD,
  GOOFYSSKYSCHOOL,
  GUARDIANSOFTHEGALAXY,
  HAUNTEDMANSIONRIDE,
  INCREDICOASTER,
  INDIANAJONESRIDE,
  INSIDEOUTEMOTIONALWHIRLWIND,
  ITSASMALLWORLD,
  JESSIESCRITTERCAROUSEL,
  JUMPINJELLYFISH,
  JUNGLECRUISERIDE,
  KINGARTHURCAROUSEL,
  LUIGISROLICKINROADSTERS,
  MADTEAPARTY,
  MATERSJUNKYARDJAMBOREE,
  MATTERHORNBOBSLEDS,
  MICKEYMINNIERUNAWAYRAILWAY,
  MONSTERSINC,
  MRTOADSWILDRIDE,
  PETERPANRIDE,
  PINOCCHIORIDE,
  PIRATESLAIR,
  PIRATESOFTHECARIBBEANRIDE,
  PIXARPALAROUND,
  PIXIEHOLLOW,
  PYMSTESTKITCHEN,
  RADIATORSPRINGSRACERS,
  RISEOFTHERESISTANCE,
  ROGERRABBITSCARTOONSPIN,
  SANFRANSOKYOSQUARE,
  SILLYSYMPHONY,
  SLEEPINGBEAUTYCASTLEWALKTHROUGH,
  SMUGGLERSRUN,
  SNOWWHITE,
  SPACEMOUNTAIN,
  STARTOURS,
  STORYBOOKLANDCANALBOATS,
  THELITTLEMERMAID,
  TIANASPLASHMOUNTAIN,
  TOYSTORYMIDWAYMANIA,
  TURTLETALK,
  WEBSLINGERS,
  WINNIETHEPOOH,
} from "./rides";

export const DISNEYLANDPARKS: Park[] = [
  {
    title: "Disneyland",
    description: "The original park",
    logo: "https://upload.wikimedia.org/wikipedia/commons/1/13/Disneyland_Park_Logo.svg",
    color: "#FF0000",
    background:
      "https://en.wikipedia.org/wiki/Disneyland#/media/File:Sleeping_Beauty_Castle_2019.jpg",
    map: {
      image:
        "https://en.wikipedia.org/wiki/Disneyland#/media/File:Disneyland_Park_Logo.svg",
      dimensions: [1000, 1000],
    },
    rides: [
      PETERPANRIDE,
      JUNGLECRUISERIDE,
      INDIANAJONESRIDE,
      PIRATESOFTHECARIBBEANRIDE,
      PIRATESLAIR,
      HAUNTEDMANSIONRIDE,
      DAVYCROCKETTSCANOESRIDE,
      WINNIETHEPOOH,
      TIANASPLASHMOUNTAIN,
      BIGTHUNDERMOUNTAIN,
      MICKEYMINNIERUNAWAYRAILWAY,
      ROGERRABBITSCARTOONSPIN,
      CHIPNDALESGADGETCOASTER,
      MRTOADSWILDRIDE,
      PINOCCHIORIDE,
      PIXIEHOLLOW,
      SNOWWHITE,
      STORYBOOKLANDCANALBOATS,
      DUMBOTHEFLYINGELEPHANT,
      CASEYJRCIRCUSTRAIN,
      ALICEINWONDERLAND,
      MATTERHORNBOBSLEDS,
      KINGARTHURCAROUSEL,
      MADTEAPARTY,
      SLEEPINGBEAUTYCASTLEWALKTHROUGH,
      STARTOURS,
      ASTROBLASTERS,
      FINDINGNEMO,
      SMUGGLERSRUN,
      RISEOFTHERESISTANCE,
      ENCHANTEDTIKIROOM,
      ITSASMALLWORLD,
      GOOFYSHOWTOYARD,
      DONALDSDUCKPOND,
      AUTOPIA,
      ALIENPIZZAPLANET,
      SPACEMOUNTAIN,
    ],
    center: [33.81209, -117.91895],
  },
  {
    title: "Disneyland California Adventure",
    description: "The newer park",
    logo: "https://upload.wikimedia.org/wikipedia/en/1/13/Disney_california_adventure_logo.svg",
    color: "#0000FF",
    background:
      "https://en.wikipedia.org/wiki/Disney_California_Adventure#/media/File:Pixarpiersunset2019_(cropped)_(cropped).jpg",
    map: {
      image:
        "https://en.wikipedia.org/wiki/Disney_California_Adventure#/media/File:Disney_california_adventure_logo.svg",
      dimensions: [1000, 1000],
    },
    rides: [
      MONSTERSINC,
      TURTLETALK,
      GUARDIANSOFTHEGALAXY,
      INCREDICOASTER,
      LUIGISROLICKINROADSTERS,
      MATERSJUNKYARDJAMBOREE,
      RADIATORSPRINGSRACERS,
      JESSIESCRITTERCAROUSEL,
      INSIDEOUTEMOTIONALWHIRLWIND,
      PIXARPALAROUND,
      TOYSTORYMIDWAYMANIA,
      GOLDENZEPHYR,
      GOOFYSSKYSCHOOL,
      JUMPINJELLYFISH,
      THELITTLEMERMAID,
      SILLYSYMPHONY,
      WEBSLINGERS,
      PYMSTESTKITCHEN,
      AVENGERSHQ,
      SANFRANSOKYOSQUARE,
    ],
    center: [33.806439962074585, -117.9196071624756],
  },
];

export const RESORTS: Record<string, Resort> = {
  Disneyland: {
    title: "Disneyland",
    description: "The original park",
    logo: "https://en.wikipedia.org/wiki/Disneyland#/media/File:Disneyland_Park_Logo.svg",
    color: "#FF0000",
    background:
      "https://en.wikipedia.org/wiki/Disneyland#/media/File:Sleeping_Beauty_Castle_2019.jpg",
    parks: DISNEYLANDPARKS,
  },
};
