import { RelatedFilm } from "../types";

export const PETERPAN: RelatedFilm = {
  title: "Peter Pan",
  year: 1953,
  genre: "Fantasy",
  runtime: "1h22m",
  rating: "G",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/02f1102f-e662-499a-a413-0e70571deb3a/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/326238e3-913d-4825-8c24-f6d71d8e4a47/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-92d66793-7198-45de-bfb6-84915256d855",
};

export const PETERPANANDWENDY: RelatedFilm = {
  title: "Peter Pan & Wendy",
  year: 2023,
  genre: "Fantasy",
  runtime: "1h47m",
  rating: "PG",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/8810550d-09ee-4205-92f5-23866222f827/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/05082508-e51a-439d-b08a-7553e1c657cc/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-7b95db94-b7dd-4986-beed-4fd9e884b2fb",
};

export const JUNGLECRUISE: RelatedFilm = {
  title: "Jungle Cruise",
  year: 2021,
  genre: "Adventure",
  runtime: "2h10m",
  rating: "PG-13",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/13c48f6e-f6ce-4f44-8fd8-e030a750f659/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/05a2f3f2-e047-4515-aff1-f26b43297f7f/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-a9225ae9-a730-4abf-be8d-f99325063955",
};

export const RAIDERSOFTHELOSTARK: RelatedFilm = {
  title: "Raiders of the Lost Ark",
  year: 1981,
  genre: "Adventure",
  runtime: "1h57m",
  rating: "PG",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/2c7a83d2-fa53-4457-898c-e91b4617c52f/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/9d95909c-ec8d-4d92-adac-0d36826c0fbd/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-ef41ef77-de3d-4df4-908c-f33b7bc2c60b",
};

export const TEMPLEOFDOOM: RelatedFilm = {
  title: "Indiana Jones and the Temple of Doom",
  year: 1984,
  genre: "Adventure",
  runtime: "2h",
  rating: "PG",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/e3483f6c-ee1e-4de1-940a-36795e46971b/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/0f0cb5c3-1b89-437f-b1a4-28cbb036d7f4/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-da4b9c74-a017-4f48-bafb-df87f125d42c",
};

export const LASTCRUSADE: RelatedFilm = {
  title: "Indiana Jones and the Last Crusade",
  year: 1989,
  genre: "Adventure",
  runtime: "2h9m",
  rating: "PG-13",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/886aee24-1332-45c3-b440-146a4165ccc6/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/389affb4-1549-4044-bf52-f2cc9ec2cff5/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-86547501-55d6-4ef7-a1e6-a38c429e2879",
};

export const PIRATES1: RelatedFilm = {
  title: "Pirates of the Caribbean: The Curse of the Black Pearl",
  year: 2003,
  genre: "Adventure",
  runtime: "2h26m",
  rating: "PG-13",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/20ecfd63-8312-4e5b-b9b4-85ee17970167/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/62f3bb11-2b1a-4e32-8337-6688b86d0a3d/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-2d6036d7-f3b4-42c3-b1d3-cd5dd79bf307",
};

export const PIRATES2: RelatedFilm = {
  title: "Pirates of the Caribbean: Dead Man's Chest",
  year: 2006,
  genre: "Adventure",
  runtime: "2h34m",
  rating: "PG-13",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/d1783de1-c3e1-40ad-bcb4-af6cb67fd186/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/5cba4a09-0cab-4dbd-8cc9-79bdd40380cf/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-615a50ef-fde7-4474-8c31-27c136cf862d",
};

export const PIRATES3: RelatedFilm = {
  title: "Pirates of the Caribbean: At World's End",
  year: 2007,
  genre: "Adventure",
  runtime: "2h51m",
  rating: "PG-13",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/4a0ba960-423d-4589-8f88-636a3679da25/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/19280a59-75f3-4ddc-907f-bf3404d64d57/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-27f5c1cb-2618-4402-9583-1dcaae0efe1d",
};

export const THEHAUNTEDMANSION: RelatedFilm = {
  title: "The Haunted Mansion",
  year: 2003,
  genre: "Comedy",
  runtime: "1h30m",
  rating: "PG",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/20452c87-3c2a-47c8-a441-f8ba004f0b40/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/ce98527f-d973-4f47-a35d-f4aa2f5a41a6/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-36dd0a60-fe80-4978-9789-ce8d5d9c5646",
};

export const HAUNTEDMANSION2023: RelatedFilm = {
  title: "Haunted Mansion",
  year: 2023,
  genre: "Comedy",
  runtime: "2h5m",
  rating: "PG-13",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/20fb3bcd-2823-45b8-9cf8-b21a8dbc5545/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/827dd4f3-1e12-4b8d-982d-442549e148e9/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-843c077e-cc0c-4b25-987b-7177bb605af8",
};

export const DAVYCROCKETTKINGOFWILD: RelatedFilm = {
  title: "Davy Crockett, King of the Wild Frontier",
  year: 1955,
  genre: "Adventure",
  runtime: "1h34m",
  rating: "PG",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/eb74a134-f0db-4a16-b36e-fc40c6f231d5/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/4c13f16f-ad71-4448-9732-329903ae33b1/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-9b3b7c50-b36c-4440-bbaf-4a2eafddfffc",
};

export const DAVYCROCKETTRIVERPIRATES: RelatedFilm = {
  title: "Davy Crockett and the River Pirates",
  year: 1956,
  genre: "Adventure",
  runtime: "1h22m",
  rating: "G",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/9b012660-4f73-457f-93c0-b4799663d853/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/efda09d2-1c03-41f1-8b02-e5cf6bac18de/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-197a854b-565b-4cc3-9606-b54dec2b3a63",
};

export const MANYADVENTURESOFWINNIETHEPOOH: RelatedFilm = {
  title: "The Many Adventures of Winnie the Pooh",
  year: 1977,
  genre: "Musical",
  runtime: "1h16m",
  rating: "TV-Y",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/6a6f42e9-b04f-4ce1-aa4e-14150dc9a898/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/37000bbc-a0c2-4d50-9236-c3d911b4eb5c/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-921fe2fd-2b3b-42c0-8f80-67239d866a2e",
};

export const WINNIEPOOH: RelatedFilm = {
  title: "Winnie the Pooh",
  year: 2011,
  genre: "Musical",
  runtime: "1h13m",
  rating: "G",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/920ff9fb-b648-4ddc-9fe0-34d9093dcd98/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/c6ac256e-c809-436f-91e1-1a66bba87848/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-12f24f7f-e650-4fa2-93ff-50e339c56b22",
};

export const PRINCESSANDFROG: RelatedFilm = {
  title: "The Princess and the Frog",
  year: 2009,
  genre: "Musical",
  runtime: "1h43m",
  rating: "G",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/b3a19ef2-d734-4240-b10e-96d9ff36456f/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/27f716a9-ff65-4144-a009-9b3e3b72779b/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-2349fffc-2124-4eca-b5e5-a8bb97e569c4",
};

export const HUCKFINN: RelatedFilm = {
  title: "The Adventures of Huck Finn",
  year: 1993,
  genre: "Adventure",
  runtime: "1h49m",
  rating: "PG",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/021c93f2-09dd-487e-a6a0-252159bfe0f4/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/abb23cf2-09b2-4db7-bec9-6a0f74965985/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-baaea4e8-191a-40ee-b5e7-ec8aef91919c",
};

export const MICKEYMOUSE: RelatedFilm = {
  title: "Mickey Mouse",
  year: 2013,
  genre: "Animation",
  runtime: "94 Episodes",
  rating: "TV-G",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/1189d6e7-5038-4712-834d-d22d7d8edc63/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/0017c063-d695-4028-bc5f-4580e53ff77b/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-9ef52e33-d305-4043-ad90-a1b718ec8f65",
};

export const WHOFRAMEDROGERRABBIT: RelatedFilm = {
  title: "Who Framed Roger Rabbit",
  year: 1988,
  genre: "Comedy",
  runtime: "1h45m",
  rating: "PG",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/4d486216-ea12-42f6-9bea-eb6393c8066c/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/814485e1-29e0-4c17-9b75-91262c5d6b83/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-40c47172-d2ea-452d-bb69-ca8f45bc2b9d",
};

export const CHIPNDALERESCUERANGERS: RelatedFilm = {
  title: "Chip 'n Dale: Rescue Rangers",
  year: 1989,
  genre: "Comedy",
  runtime: "65 Episodes",
  rating: "TV-Y7",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/d2bd298d-7072-449c-9db2-fe6d6082138b/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/e250ee0a-ca9e-415c-bcae-953d30ff1679/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/d2bd298d-7072-449c-9db2-fe6d6082138b/trim?format=webp&max=800%7C300",
};

export const CHIPNDALERESCUERANGERSNEW: RelatedFilm = {
  title: "Chip 'n Dale: Rescue Rangers",
  year: 2022,
  genre: "Comedy",
  runtime: "1h39m",
  rating: "PG",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/f572a4de-ff4e-4664-ba70-e8ce3fc44613/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/0648963f-4c98-47b4-83bf-899ffaf47e71/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/f572a4de-ff4e-4664-ba70-e8ce3fc44613/trim?format=webp&max=800%7C300",
};

export const ICHABODANDMRTOAD: RelatedFilm = {
  title: "The Adventures of Ichabod and Mr. Toad",
  year: 1949,
  genre: "Animation",
  runtime: "1h10m",
  rating: "G",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/7125c59f-fc17-4d82-87ec-e912c899ecc1/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/978c5af2-0ba5-4fb9-99fb-2ec818886463/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-727d398f-4326-4e08-b3aa-055db5b4d3de",
};

export const PINOCCHIO: RelatedFilm = {
  title: "Pinocchio",
  year: 1940,
  genre: "Animation",
  runtime: "1h32m",
  rating: "G",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/5ebe5ccf-cf79-4bae-8bb5-efc4ed4015f7/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/409cd99d-93a6-4d94-a4c9-3255a4097d37/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-bfe9c61c-f7f3-4e39-94bb-376bf2162c28",
};

export const PINOCCHIO2022: RelatedFilm = {
  title: "Pinocchio",
  year: 2022,
  genre: "Fantasy",
  runtime: "1h53m",
  rating: "PG",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/a3827bec-da24-4181-aef8-ea66e89bed79/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/f4af545f-b1fe-427f-824c-3a40b354e04b/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-490f7ddc-671e-4530-b8ca-d48d64ccbb10",
};

export const TINKERBELL: RelatedFilm = {
  title: "Tinker Bell",
  year: 2008,
  genre: "Fantasy",
  runtime: "1h24m",
  rating: "G",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/7dc991c4-0eeb-42c5-9da1-6925807ad930/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/804798ac-69c0-41c8-8afb-503e5b0bd6a7/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-30b0a5b5-865d-451e-ba08-2395d35acc76",
};

export const SNOWWHITEANDTHESEVENDWARVES: RelatedFilm = {
  title: "Snow White and the Seven Dwarfs",
  year: 1937,
  genre: "Fantasy",
  runtime: "1h29m",
  rating: "G",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/f016f575-b769-4100-b8ee-994841755e9f/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/c099bf40-1067-4ea0-a6c7-b9dd17faf4c0/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-f51f7e6c-2d9a-443c-9831-f3cc22e822b4",
};

export const ALICEINWONDERLANDFILM: RelatedFilm = {
  title: "Alice in Wonderland",
  year: 1951,
  genre: "Fantasy",
  runtime: "1h22m",
  rating: "G",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/aaa8ea8a-cdb9-405e-b8be-ab26674d1cdd/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/0d39fa60-d223-4483-b611-7accc237335f/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-7ccabaf6-d01a-4163-a613-862f2c4f1446",
};

export const ALICEINWONDERLAND2010: RelatedFilm = {
  title: "Alice in Wonderland",
  year: 2010,
  genre: "Fantasy",
  runtime: "1h53m",
  rating: "PG",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/5131344c-f7fb-4a19-b85a-9ac5e3ddc012/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/f6d95fb1-3187-4521-a656-3c3057383e00/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-072d2f34-c99e-4bf7-a342-a1b30a03b2e7",
};

export const ALICETHROUGHTHELOOKINGGLASS: RelatedFilm = {
  title: "Alice Through the Looking Glass",
  year: 2016,
  genre: "Fantasy",
  runtime: "1h53m",
  rating: "PG",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/9ae017c7-e96c-46e1-84e3-8da9eee31bb5/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/6e3b489a-5697-4756-b986-82d946c162c1/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-94867b85-67d2-40d9-9215-b3cfd9c5f824",
};

export const CINDERELLA: RelatedFilm = {
  title: "Cinderella",
  year: 1950,
  genre: "Fantasy",
  runtime: "1h19m",
  rating: "G",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/b72c6659-5b53-47bf-a748-bcd83fc9dcdf/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/3853e121-f71b-4fc8-9668-da63415c17c6/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-f7272318-0b08-46f5-b89e-284b3e8a7234",
};

export const CINDERELLA2015: RelatedFilm = {
  title: "Cinderella",
  year: 2015,
  genre: "Fantasy",
  runtime: "1h51m",
  rating: "PG",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/9f547f02-ffc6-45be-b594-a6e8d6b955f9/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/888bd371-aa9e-4840-a7dd-83aedd51f9df/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-7dfd6ef1-5543-4eac-a6bf-3f50ebccad3a",
};

export const THIRDMANONTHEMOUNTAIN: RelatedFilm = {
  title: "Third Man on the Mountain",
  year: 1959,
  genre: "Adventure",
  runtime: "1h45m",
  rating: "G",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/f3a38c89-3bb7-4170-bade-b404776b7652/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/0da7128c-4b93-48fa-b1ce-153049f5c59a/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-74f19f3d-0fba-47be-b273-2ae7c0690cf7",
};

export const SLEEPINGBEAUTY: RelatedFilm = {
  title: "Sleeping Beauty",
  year: 1959,
  genre: "Fantasy",
  runtime: "1h15m",
  rating: "G",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/649a08e7-55f2-4f11-b6a7-790667822fdf/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/31fd9146-c622-4f1f-be98-eabbae929b67/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-2f6f4b9b-9d3b-4f3d-8c2e-3b3f9a0f5b9b",
};

export const STARWARSANEWHOPE: RelatedFilm = {
  title: "Star Wars: A New Hope",
  year: 1977,
  genre: "Sci-Fi",
  runtime: "2h6m",
  rating: "PG",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/9b9d43b9-0578-4d3d-93b6-6cb9119d2d2b/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/2bc1e140-ab14-48ab-bf6c-f9983bad5499/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-9a280e53-fcc0-4e17-a02c-b1f40913eb0b",
};

export const STARWARSTHEEMPIRE: RelatedFilm = {
  title: "Star Wars: The Empire Strikes Back",
  year: 1980,
  genre: "Sci-Fi",
  runtime: "2h9m",
  rating: "PG",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/c7bd90b5-2b85-46f7-9a17-b167cc47cd32/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/39a47257-4260-4984-bd87-53de2ce6e9a8/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-0f5c5223-f4f6-46ef-ba8a-69cb0e17d8d3",
};

export const STARWARSRETURNOFTHEJEDI: RelatedFilm = {
  title: "Star Wars: Return of the Jedi",
  year: 1983,
  genre: "Sci-Fi",
  runtime: "2h17m",
  rating: "PG",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/90161d2b-0861-4c5b-bc97-14d05fc4f7df/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/53191b6e-8d01-4b1c-b7f9-e21ec5b98bb4/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-4b6e7cda-daa5-4f2d-9b61-35bbe562c69c",
};

export const STARWARSTHEFORCEAWAKENS: RelatedFilm = {
  title: "Star Wars: The Force Awakens",
  year: 2015,
  genre: "Sci-Fi",
  runtime: "2h22m",
  rating: "PG-13",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/767269c6-cafc-4641-925f-f8175c7e05c1/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/f8775ef2-54b2-49d2-a7a5-d7cd01b59098/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-2854a94d-3702-40bd-97a4-12d55a809188",
};

export const STARWARSTHELASTJEDI: RelatedFilm = {
  title: "Star Wars: The Last Jedi",
  year: 2017,
  genre: "Sci-Fi",
  runtime: "2h34m",
  rating: "PG-13",
  logo: "https://prod-ripcut-delivery.disney-plus.net/v1/variant/disney/FB91D7E7CBDBA3129B60823241A6EDA42DCFDDF47301E3579BC5F48F0DA79656/scale?width=1920&aspectRatio=1.78&format=png",
  background:
    "https://prod-ripcut-delivery.disney-plus.net/v1/variant/disney/9B44727226905C8BB1269C6A003C76FC8928F001794FB9D1A8BF97CA4C7838F6/scale?width=2880&aspectRatio=1.78&format=jpeg",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/movies/details/1nCWFK1pmAI0",
};

export const STARWARSTHERISEOFSKYWALKER: RelatedFilm = {
  title: "Star Wars: The Rise of Skywalker",
  year: 2019,
  genre: "Sci-Fi",
  runtime: "2h22m",
  rating: "PG-13",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/4982ad2d-d9ba-44e8-8e7f-b91405895adb/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/ce0eba84-d89d-4b6d-a74c-861af84733af/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-43f9c275-e7e8-4ab3-802d-00d06a8ad841",
};

export const STARWARSTHEPHANTOMMENACE: RelatedFilm = {
  title: "Star Wars: The Phantom Menace",
  year: 1999,
  genre: "Sci-Fi",
  runtime: "2h16m",
  rating: "PG",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/213fe4af-b9bc-4702-a5a3-27cf6c678c84/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/2cfeb9c8-56f3-4a29-a64f-f7cab736cb2a/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-e0a9fee4-2959-4077-ad8c-8fab4fd6e4d1",
};

export const STARWARSATTACKOFTHECLONES: RelatedFilm = {
  title: "Star Wars: Attack of the Clones",
  year: 2002,
  genre: "Sci-Fi",
  runtime: "2h22m",
  rating: "PG",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/bcff841e-f408-4030-924c-aee79d2dbcbf/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/6c598330-4496-4fdd-80ca-f1f56e65ab53/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-39cbdf17-1bbe-4de2-b4a4-8e342875c2c6",
};

export const STARWARSREVENGEOFTHESITH: RelatedFilm = {
  title: "Star Wars: Revenge of the Sith",
  year: 2005,
  genre: "Sci-Fi",
  runtime: "2h20m",
  rating: "PG-13",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/51783b95-e9e2-4920-a8b9-f28f42d6994e/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/2067e6ce-c940-4499-a2ee-f64512d1bec7/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-eb1e2c5f-69bf-4240-a61f-7ffc4e0311b3",
};

export const ROGUEONE: RelatedFilm = {
  title: "Rogue One: A Star Wars Story",
  year: 2016,
  genre: "Sci-Fi",
  runtime: "2h13m",
  rating: "PG-13",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/f0f42dd9-e206-4351-b1c5-579188c564b1/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/c7402681-6022-4862-9005-32bfefcdc558/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-5ec74210-f970-42b7-a39f-8653c0a9eab8",
};

export const AHSOKA: RelatedFilm = {
  title: "Ahsoka",
  year: 2023,
  genre: "Sci-Fi",
  runtime: "8 Episodes",
  rating: "TV-14",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/30067435-55ac-4ed5-b1ab-53055b997720/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/70011670-1da1-45f6-9707-49dbb2548e24/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-e64c22fc-fc7c-4ada-bbec-ab8351b8db97",
};

export const THEMANDALORIAN: RelatedFilm = {
  title: "The Mandalorian",
  year: 2019,
  genre: "Sci-Fi",
  runtime: "24 Episodes",
  rating: "TV-14",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/10beae6c-5218-467d-9e37-4eb9af20d359/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/1a4b562f-b3d2-4435-a060-67a6559a9688/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-422f6dcc-226f-44e7-98d4-22de69b31cf3",
};

export const TOYSTORY: RelatedFilm = {
  title: "Toy Story",
  year: 1995,
  genre: "Animation",
  runtime: "1h21m",
  rating: "G",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/26b673f1-1c09-4886-924c-e8918c5a4085/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/9419e26d-61aa-41d1-af0c-be1af1c2a3bc/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-f6174ebf-cb92-453c-a52b-62bb3576e402",
};

export const TOYSTORY2: RelatedFilm = {
  title: "Toy Story 2",
  year: 1999,
  genre: "Animation",
  runtime: "1h32m",
  rating: "G",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/0ec65220-5e95-4e61-8df3-92808c2963ce/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/308cba83-c525-409c-b27f-60fd9a0c4a30/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-55bb8618-baac-449e-9f63-f402f41371a2",
};

export const TOYSTORY3: RelatedFilm = {
  title: "Toy Story 3",
  year: 2010,
  genre: "Animation",
  runtime: "1h43m",
  rating: "G",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/97e9d5a5-89c5-498e-b46f-fe49f95564f9/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/a6c2d0e5-a3aa-4b69-8f44-554cdde6fc1e/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-95e7b2ce-5f45-4923-976d-b7e9968a7357",
};

export const TOYSTORY4: RelatedFilm = {
  title: "Toy Story 4",
  year: 2019,
  genre: "Animation",
  runtime: "1h40m",
  rating: "G",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/ae4cc331-c493-478f-af29-629f55e08b18/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/019ec554-1499-4b78-8e51-1ffa7a5d73a3/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-97d822a3-7dad-4d85-8350-ce4f8642511e",
};

export const FINDINGNEMOFILM: RelatedFilm = {
  title: "Finding Nemo",
  year: 2003,
  genre: "Animation",
  runtime: "1h40m",
  rating: "G",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/fb65b963-f47f-4686-82c7-61ca978c777b/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/943b879f-b365-4200-8ac0-caad41e4f95a/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-37b62808-2368-4688-9410-2dcf7461e258",
};

export const FINDINGDORY: RelatedFilm = {
  title: "Finding Dory",
  year: 2016,
  genre: "Animation",
  runtime: "1h37m",
  rating: "PG",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/80470fc3-8a51-43de-baec-c2483ff78648/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/10fe243f-4a1b-4aa0-9aaa-b057fd26e5f4/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-1898d521-c10f-46ca-b253-432a9eb5416f",
};

export const MONSTERSINCFILM: RelatedFilm = {
  title: "Monsters, Inc.",
  year: 2001,
  genre: "Animation",
  runtime: "1h32m",
  rating: "G",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/6fd4f58e-d483-45fa-a97a-f0c555391f74/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/de88d02e-64f5-4dea-bdb3-7e1dcb58f5b0/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-3c90b85f-ba5e-4351-be87-e625d5706952",
};

export const MONSTERSUNIVERSITY: RelatedFilm = {
  title: "Monsters University",
  year: 2013,
  genre: "Animation",
  runtime: "1h44m",
  rating: "G",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/db5bfc2e-bd5f-4ffe-a188-0ddd316dbbfe/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/ed79bb1e-8098-4a87-9270-a166d4f34e88/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-9b76e5cf-4005-4f0b-ae54-e65f3a6ccb61",
};

export const THEINCREDIBLES: RelatedFilm = {
  title: "The Incredibles",
  year: 2004,
  genre: "Animation",
  runtime: "1h55m",
  rating: "PG",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/18ac8b82-5008-40a2-9886-a8534296606b/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/ce55de3e-1183-4dd4-88c2-b7d2492756ba/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-850b6e92-07ea-4211-b3c3-cbbf1de045fa",
};

export const THEINCREDIBLES2: RelatedFilm = {
  title: "The Incredibles 2",
  year: 2018,
  genre: "Animation",
  runtime: "1h58m",
  rating: "PG",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/1bd421c3-f5dc-4988-ba16-0395597e5297/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/9fb1c45b-319c-42ae-b83c-e1f7d9e81026/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-9da2c0fb-a380-4180-b67f-006fbaaa89ab",
};

export const GUARDIANSOFTHEGALAXYFILM: RelatedFilm = {
  title: "Guardians of the Galaxy",
  year: 2014,
  genre: "Sci-Fi",
  runtime: "2h1m",
  rating: "PG-13",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/3bec99a2-5b29-494c-80df-a38f9ea5bc10/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/30286403-220e-4b5e-b3c5-82943a75ed5b/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-c9ee959b-7249-4a4c-9708-9ffd1ddb00f1",
};

export const GUARDIANSOFTHEGALAXYVOL2: RelatedFilm = {
  title: "Guardians of the Galaxy Vol. 2",
  year: 2017,
  genre: "Sci-Fi",
  runtime: "2h16m",
  rating: "PG-13",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/7b978ce8-ab04-429b-ac08-f41a1c63b0db/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/56d4fdce-d185-4403-9008-b6733b252700/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-9fcd7087-c151-49dc-aeb9-b2f9943fd4e6",
};

export const GUARDIANSOFTHEGALAXYVOL3: RelatedFilm = {
  title: "Guardians of the Galaxy Vol. 3",
  year: 2023,
  genre: "Sci-Fi",
  runtime: "2h33m",
  rating: "PG-13",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/b67827d8-0d73-4cbe-b6bc-c1299521576f/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/d00941b5-b2cd-4c57-9406-94dce07819db/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-820f0ded-3254-42c5-a7e4-7c044ff6dd65",
};

export const CARS: RelatedFilm = {
  title: "Cars",
  year: 2006,
  genre: "Animation",
  runtime: "1h56m",
  rating: "G",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/cde58904-5f37-4033-9e20-796a06155876/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/762da5b9-fea0-48b5-a24e-1aa7eaa2d50f/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-9c1b0ec2-2e4e-4717-89fb-bdf3a45523df",
};

export const CARS2: RelatedFilm = {
  title: "Cars 2",
  year: 2011,
  genre: "Animation",
  runtime: "1h47m",
  rating: "G",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/e2480e6c-e9ce-44a8-9ea9-197a270cf789/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/f64608f1-4819-44f8-a246-01421d12e8df/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-fe92916d-0f0e-47ad-ad34-5931e59b42ea",
};

export const CARS3: RelatedFilm = {
  title: "Cars 3",
  year: 2017,
  genre: "Animation",
  runtime: "1h42m",
  rating: "G",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/5a6583ed-0246-45d8-9bea-f369ea8c635d/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/45da6436-99c3-4b46-a87c-2c60dbcdb151/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-610e18a4-d8d8-43b5-8051-672655e715f3",
};

export const INSIDEOUT: RelatedFilm = {
  title: "Inside Out",
  year: 2015,
  genre: "Animation",
  runtime: "1h35m",
  rating: "PG",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/7d3bf180-b46d-419f-b0d1-1979358da112/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/61072c95-b330-4cf6-9e98-c4cf048d9fc5/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-d4b87168-7d0b-49bc-b138-457ab7723feb",
};

export const AGOOFYMOVIE: RelatedFilm = {
  title: "A Goofy Movie",
  year: 1995,
  genre: "Animation",
  runtime: "1h18m",
  rating: "G",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/7c65173f-1a0a-4c87-967b-265f3150d036/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/1f00b4a9-d925-4ad6-a61c-1577d37794c0/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-dc9c2716-f06a-498d-b689-66f6bf70480d",
};

export const ANEXTREMELYGOOFYMOVIE: RelatedFilm = {
  title: "An Extremely Goofy Movie",
  year: 2000,
  genre: "Animation",
  runtime: "1h19m",
  rating: "G",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/611c8f37-b3a1-46cd-96b8-9eead96a76f2/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/01f514d8-a90f-4e00-9704-e4b96faa1d57/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-f12466f8-6871-43ba-9463-b5f352f4d6ca",
};

export const THELITTLEMERMAIDFILM: RelatedFilm = {
  title: "The Little Mermaid",
  year: 1989,
  genre: "Animation",
  runtime: "1h23m",
  rating: "G",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/d7460bd2-682d-4cf8-887b-a0ab02010a2e/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/5a294e84-687c-45b0-a993-236d1e1813e4/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-f7643452-fe64-4b05-8f09-c8bea9b2dd60",
};

export const THELITTLEMERMAID2023: RelatedFilm = {
  title: "The Little Mermaid",
  year: 2023,
  genre: "Fantasy",
  runtime: "2h21m",
  rating: "PG",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/8902b42a-0ce2-45a9-ae87-69b4c082101b/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/0eafdb4e-373c-4024-9b2e-742db5bb94c1/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-50d2a9c6-3c88-4329-8ba8-29f87d8ee3b1",
};

export const SPIDERMANHOMECOMING: RelatedFilm = {
  title: "Spider-Man: Homecoming",
  year: 2017,
  genre: "Action",
  runtime: "2h13m",
  rating: "PG-13",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/92fed3ed-4dbe-4124-8e36-993dbb7c95e6/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/560a0ec3-e005-4782-a850-6781c72ee480/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-5b2b999a-045e-4d89-af52-390c257178db",
};

export const SPIDERMANFARFROMHOME: RelatedFilm = {
  title: "Spider-Man: Far From Home",
  year: 2019,
  genre: "Action",
  runtime: "2h9m",
  rating: "PG-13",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/38aac5e3-6d70-43fa-86ce-f762dc122afa/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/9826a7c7-1937-458f-a527-a259d5863db6/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-2ca3cca0-bd2b-4934-93c0-c03d27fb249e",
};

export const ANTMAN: RelatedFilm = {
  title: "Ant-Man",
  year: 2015,
  genre: "Action",
  runtime: "1h57m",
  rating: "PG-13",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/4f365f7f-e27f-4efc-84c9-4bee92b0650b/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/f0477db7-e5a9-4b80-8fa7-b87120f30ead/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-af42798c-b9db-457f-b748-5e1f029c1ece",
};

export const ANTMANANDTHEWASP: RelatedFilm = {
  title: "Ant-Man and the Wasp",
  year: 2018,
  genre: "Action",
  runtime: "1h58m",
  rating: "PG-13",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/c6728667-9f1b-412a-aee9-0427645fe5dd/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/b1758046-5cd5-459b-9dd3-1ed415398ba0/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-3533ca7b-e187-4bf5-a65c-7c903cd2d4ad",
};

export const ANTMANANDTHEWASPQUANTUMANIA: RelatedFilm = {
  title: "Ant-Man and the Wasp: Quantumania",
  year: 2023,
  genre: "Action",
  runtime: "2h3m",
  rating: "PG-13",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/340fe346-7d67-4379-a494-25c479a05e8d/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/e3f3b588-d7f2-4e7b-93d3-4f8fc898a3ba/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-eb1453f3-48aa-4af0-85f5-8ca4916c7415",
};

export const THEAVENGERS: RelatedFilm = {
  title: "The Avengers",
  year: 2012,
  genre: "Action",
  runtime: "2h23m",
  rating: "PG-13",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/f100aa51-2109-4477-8b5e-ce9de5d02f68/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/3132b3f5-2d04-4e66-a1bb-13591cf0d7d1/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-3a5596d6-5133-4a8e-8d21-00e1531a4e0f",
};

export const AVENGERSAGEOFULTRON: RelatedFilm = {
  title: "Avengers: Age of Ultron",
  year: 2015,
  genre: "Action",
  runtime: "2h21m",
  rating: "PG-13",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/b4a031c4-f59c-47c8-b090-809a1812263d/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/01461ddd-13cd-40cc-994d-fb29897d0156/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-39740da6-d484-471b-8dd7-a70c6151d705",
};

export const AVENGERSINFINITYWAR: RelatedFilm = {
  title: "Avengers: Infinity War",
  year: 2018,
  genre: "Action",
  runtime: "2h29m",
  rating: "PG-13",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/caee916b-4409-401c-a5b7-8b66f7ce0233/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/7f437209-d0bf-4bc6-871e-d4f533945b98/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-9a136e06-852a-41bf-b71d-fa061cb43225",
};

export const AVENGERSENDGAME: RelatedFilm = {
  title: "Avengers: Endgame",
  year: 2019,
  genre: "Action",
  runtime: "3h1m",
  rating: "PG-13",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/a9ea3e10-77e9-4965-98b4-323efcc41257/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/76cccf3e-ee6e-458d-9fb3-c7c440aff0e2/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-b39aa962-be56-4b09-a536-98617031717f",
};

export const BIGHERO6: RelatedFilm = {
  title: "Big Hero 6",
  year: 2014,
  genre: "Animation",
  runtime: "1h42m",
  rating: "PG",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/243e6e58-ce44-4ee8-b702-a0004b823212/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/5b4b072a-1c8f-47d2-81ab-8457d6d2d0e0/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-c29f81d8-8c51-4fe7-bb0c-13f099ad3e90",
};

export const DUCKTALESNEW: RelatedFilm = {
  title: "DuckTales",
  year: 2017,
  genre: "Animation",
  runtime: "75 Episodes",
  rating: "TV-Y7",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/4a353e83-9f22-45c4-aa9d-18a780aea4f0/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/b3d7fc3a-a3ae-4ad4-b761-e609d4d49687/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-55f1a4e7-72e5-44dc-9600-4d39d21065a2",
};

export const DUCKTALES: RelatedFilm = {
  title: "DuckTales",
  year: 1987,
  genre: "Animation",
  runtime: "100 Episodes",
  rating: "TV-Y7",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/09143408-d7be-40ac-a921-6be05319ca77/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/087cccae-4c40-4409-9aaf-a5312eb61f34/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-d21f9ec9-14f4-4c26-ba02-199108dac9ff",
};

export const DUMBO: RelatedFilm = {
  title: "Dumbo",
  year: 1941,
  genre: "Animation",
  runtime: "1h4m",
  rating: "G",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/3e03b7b4-ad78-4be6-90cb-40c0faa18879/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/9f77eea1-9805-4a80-b56d-abc20ba71102/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-d71e65f7-36f1-4ae1-9e3b-4a794d0c970c",
};

export const DUMBO2019: RelatedFilm = {
  title: "Dumbo",
  year: 2019,
  genre: "Fantasy",
  runtime: "1h52m",
  rating: "PG",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/c1d7bd0f-5e27-432c-9f3b-96a439f3280b/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/ef558f7f-3050-4d65-8a9e-b8b81640e6d5/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-f959a78b-e3ae-40b5-8289-a3407e362f5e",
};

export const ALADDIN: RelatedFilm = {
  title: "Aladdin",
  year: 1992,
  genre: "Animation",
  runtime: "1h30m",
  rating: "G",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/890e0b06-0d48-4b1f-b354-3e8cea77008d/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/ed2921f7-da30-441b-82ec-7465f0d5e476/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-bfad6284-a0aa-4ae1-8469-dc1653121dbb",
};

export const FROZEN: RelatedFilm = {
  title: "Frozen",
  year: 2013,
  genre: "Animation",
  runtime: "1h42m",
  rating: "PG",
  logo: "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/c3a893a7-1384-49ed-9994-be92e95e9587/trim?format=webp&max=800%7C300",
  background:
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/1cdac143-d120-4ef4-836a-562c6b3c5297/compose?format=webp&width=2880",
  primaryAdaptation: false,
  link: "https://www.disneyplus.com/browse/entity-04c97b72-504b-47f2-9c6f-fe13d9aea82f",
};
