import React from "react";
import { Resort } from "../types";

const MapHeader = (props: {
  resort: Resort;
  selectedPark: string;
  selectPark: (parkName: string) => void;
}) => {
  const { resort, selectPark, selectedPark } = props;

  return (
    <header className="mt-4 absolute z-10">
      <h1 className="font-bold text-gray-950 text-md tracking-[0.2em] uppercase text-center leading-6 mx-2">
        Disney Parks Watch Guide
      </h1>
      <div className="flex gap-2 justify-center items-center mt-1.5">
        {resort.parks.map((park) => (
          <button
            key={`park-${park.title}`}
            onClick={() => selectPark(park.title)}
            className={`bg-white text-black px-4 py-2 rounded-full drop-shadow hover:opacity-100 transition-opacity ${park.title === selectedPark ? "opacity-100" : "opacity-50 grayscale"}`}
          >
            <img src={park.logo} alt={`${park.title} logo`} className="h-4" />
          </button>
        ))}
      </div>
    </header>
  );
};

export default MapHeader;
