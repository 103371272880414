import React, { useMemo, useState } from "react";
import Map from "../components/Map";
import { Park, ParkRide, Resort } from "../types";
import DetailPanel from "../components/DetailPanel";
import MapHeader from "../components/MapHeader";

const Guide = (props: { resort: Resort }) => {
  const { resort } = props;
  const [selectedPark, selectPark] = useState<string>(resort.parks[0].title);
  const [selectedRide, selectRide] = useState<string>();
  const parkData = useMemo(
    () => resort.parks.find((park: Park) => park.title === selectedPark),
    [resort.parks, selectedPark],
  );
  const rideData = useMemo(
    () =>
      parkData &&
      parkData.rides.find((ride: ParkRide) => ride.title === selectedRide),
    [parkData, selectedRide],
  );

  return (
    <div className="absolute inset-0 flex flex-col items-center">
      <MapHeader
        resort={resort}
        selectPark={selectPark}
        selectedPark={selectedPark}
      />
      {parkData && (
        <Map
          park={parkData}
          selectedRide={selectedRide}
          selectRide={selectRide}
        />
      )}
      <DetailPanel ride={rideData} onClose={() => selectRide(undefined)} />
    </div>
  );
};

export default Guide;
