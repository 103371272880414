import {
  Boat,
  Car,
  Fan,
  ForkKnife,
  GearFine,
  MagicWand,
  MicrophoneStage,
  Storefront,
  Subway,
  Train,
  Visor,
  Waves,
} from "@phosphor-icons/react";
import { ParkRide } from "./types";

export const colors = {
  primary: {
    white: "#f9fafb",
    gray: "#1f2937",
    black: "#030712",
  },
  tertiary: {
    // 600 tailwind values
    red: "#dc2626",
    orange: "#ea580c",
    yellow: "#ca8a04",
    lime: "#65a30d",
    green: "#16a34a",
    teal: "#0d9488",
    cyan: "#0891b2",
    blue: "#2563eb",
    darksky: "#0c4a6e",
    indigo: "#4f46e5",
    purple: "#9333ea",
    pink: "#db2777",
  },
  dev: "#FF00FF",
};

export const markerIconMap = new Map<
  ParkRide["type"],
  (big: boolean) => JSX.Element
>([
  [
    "Roller Coaster",
    (big) => <Train size={big ? 18 : 14} color={colors.primary.white} />,
  ],
  [
    "Boat Ride",
    (big) => <Boat size={big ? 18 : 14} color={colors.primary.white} />,
  ],
  [
    "Water Ride",
    (big) => <Waves size={big ? 18 : 14} color={colors.primary.white} />,
  ],
  [
    "Dark Ride",
    (big) => <Subway size={big ? 18 : 14} color={colors.primary.white} />,
  ],
  [
    "Motion Simulator",
    (big) => <Visor size={big ? 18 : 14} color={colors.primary.white} />,
  ],
  [
    "Carousel",
    (big) => <GearFine size={big ? 18 : 14} color={colors.primary.white} />,
  ],
  [
    "Spinning Ride",
    (big) => <Fan size={big ? 18 : 14} color={colors.primary.white} />,
  ],
  [
    "Entertainment",
    (big) => (
      <MicrophoneStage size={big ? 18 : 14} color={colors.primary.white} />
    ),
  ],
  [
    "Dining",
    (big) => <ForkKnife size={big ? 18 : 14} color={colors.primary.white} />,
  ],
  [
    "Retail",
    (big) => <Storefront size={big ? 18 : 14} color={colors.primary.white} />,
  ],
  [
    "Transportation",
    (big) => <Car size={big ? 18 : 14} color={colors.primary.white} />,
  ],
  [
    "Other",
    (big) => <MagicWand size={big ? 18 : 14} color={colors.primary.white} />,
  ],
]);

export const markerColorMap = new Map([
  ["Roller Coaster", colors.tertiary.red],
  ["Boat Ride", colors.tertiary.blue],
  ["Water Ride", colors.tertiary.cyan],
  ["Dark Ride", colors.tertiary.darksky],
  ["Roller Coaster", colors.tertiary.red],
  ["Motion Simulator", colors.tertiary.purple],
  ["Carousel", colors.tertiary.orange],
  ["Spinning Ride", colors.tertiary.pink],
  ["Entertainment", colors.tertiary.yellow],
  ["Dining", colors.tertiary.green],
  ["Retail", colors.tertiary.teal],
  ["Transportation", colors.tertiary.indigo],
  ["Other", colors.primary.gray],
]);
