import { ParkRide } from "../types";
import {
  AGOOFYMOVIE,
  AHSOKA,
  ALADDIN,
  ALICEINWONDERLAND2010,
  ALICEINWONDERLANDFILM,
  ALICETHROUGHTHELOOKINGGLASS,
  ANEXTREMELYGOOFYMOVIE,
  ANTMAN,
  ANTMANANDTHEWASP,
  ANTMANANDTHEWASPQUANTUMANIA,
  AVENGERSAGEOFULTRON,
  AVENGERSENDGAME,
  AVENGERSINFINITYWAR,
  BIGHERO6,
  CARS,
  CARS2,
  CARS3,
  CHIPNDALERESCUERANGERS,
  CHIPNDALERESCUERANGERSNEW,
  CINDERELLA,
  DAVYCROCKETTKINGOFWILD,
  DAVYCROCKETTRIVERPIRATES,
  DUCKTALES,
  DUCKTALESNEW,
  DUMBO,
  DUMBO2019,
  FINDINGDORY,
  FINDINGNEMOFILM,
  FROZEN,
  GUARDIANSOFTHEGALAXYFILM,
  GUARDIANSOFTHEGALAXYVOL2,
  GUARDIANSOFTHEGALAXYVOL3,
  HAUNTEDMANSION2023,
  HUCKFINN,
  ICHABODANDMRTOAD,
  INSIDEOUT,
  JUNGLECRUISE,
  LASTCRUSADE,
  MANYADVENTURESOFWINNIETHEPOOH,
  MICKEYMOUSE,
  MONSTERSINCFILM,
  MONSTERSUNIVERSITY,
  PETERPAN,
  PETERPANANDWENDY,
  PINOCCHIO,
  PINOCCHIO2022,
  PIRATES1,
  PIRATES2,
  PIRATES3,
  PRINCESSANDFROG,
  RAIDERSOFTHELOSTARK,
  SLEEPINGBEAUTY,
  SNOWWHITEANDTHESEVENDWARVES,
  SPIDERMANFARFROMHOME,
  SPIDERMANHOMECOMING,
  STARWARSANEWHOPE,
  STARWARSATTACKOFTHECLONES,
  STARWARSRETURNOFTHEJEDI,
  STARWARSREVENGEOFTHESITH,
  STARWARSTHEEMPIRE,
  STARWARSTHEFORCEAWAKENS,
  STARWARSTHELASTJEDI,
  STARWARSTHEPHANTOMMENACE,
  STARWARSTHERISEOFSKYWALKER,
  TEMPLEOFDOOM,
  THEAVENGERS,
  THEHAUNTEDMANSION,
  THEINCREDIBLES,
  THEINCREDIBLES2,
  THELITTLEMERMAID2023,
  THELITTLEMERMAIDFILM,
  THEMANDALORIAN,
  THIRDMANONTHEMOUNTAIN,
  TINKERBELL,
  TOYSTORY,
  TOYSTORY2,
  TOYSTORY3,
  TOYSTORY4,
  WHOFRAMEDROGERRABBIT,
  WINNIEPOOH,
} from "./movies";

export const PETERPANRIDE: ParkRide = {
  geo: [33.8131, -117.9188],
  title: "Peter Pan's Flight",
  type: "Dark Ride",
  year: 1955,
  heightRequirement: 0,
  description:
    "Soar over London aboard an enchanted pirate ship to Never Land with the boy who wouldn’t grow up.",
  relatedFilms: [{ ...PETERPAN, primaryAdaptation: true }, PETERPANANDWENDY],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/dam/wdpro-assets/dlr/parks-and-tickets/attractions/disneyland/peter-pans-flight/peter-pan-flight-00.jpg?1699623622994",
};

export const JUNGLECRUISERIDE: ParkRide = {
  geo: [33.81142097568932, -117.9201087355614],
  title: "Jungle Cruise",
  type: "Boat Ride",
  year: 1955,
  heightRequirement: 0,
  description:
    "Cast off on a guided tour of the world’s most remote rivers where adventure abounds—and the animals get the last laugh.",
  relatedFilms: [JUNGLECRUISE],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/dam/wdpro-assets/dlr/parks-and-tickets/attractions/disneyland/jungle-cruise/jungle-cruise-00.jpg?1699631769575",
};

export const INDIANAJONESRIDE: ParkRide = {
  geo: [33.81101091661872, -117.92051374912263],
  title: "Indiana Jones Adventure",
  type: "Dark Ride",
  year: 1995,
  heightRequirement: 46,
  description:
    "Embark on a fast-paced thrill ride in search of Indiana Jones—enter this cursed temple at your own risk!",
  relatedFilms: [RAIDERSOFTHELOSTARK, TEMPLEOFDOOM, LASTCRUSADE],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/630/354/75/dam/wdpro-assets/dlr/parks-and-tickets/attractions/disneyland/indiana-jones-adventure/indiana-jones-adventure-00.jpg?1699632191044",
};

export const PIRATESOFTHECARIBBEANRIDE: ParkRide = {
  geo: [33.81128503241087, -117.9208289086819],
  title: "Pirates of the Caribbean",
  type: "Boat Ride",
  year: 1967,
  heightRequirement: 0,
  description:
    "Steer a course for the Golden Age of Piracy on a swashbuckling cruise through seas plagued by scoundrels.",
  relatedFilms: [PIRATES1, PIRATES2, PIRATES3],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/630/354/75/dam/wdpro-assets/dlr/parks-and-tickets/attractions/disneyland/pirates-of-the-caribbean/pirates-of-the-caribbean-00.jpg?1699632244891",
};

export const HAUNTEDMANSIONRIDE: ParkRide = {
  geo: [33.811759718656475, -117.92209893465045],
  title: "Haunted Mansion",
  type: "Dark Ride",
  year: 1969,
  heightRequirement: 0,
  description:
    "Take your seat in a gloomy Doom Buggy for an eerie tour through this house of happy haunts.",
  relatedFilms: [THEHAUNTEDMANSION, HAUNTEDMANSION2023],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/630/354/75/dam/disneyland/attractions/haunted-mansion/hanted-mansion-exterior-16x9.jpg?1699631836595",
};

export const DAVYCROCKETTSCANOESRIDE: ParkRide = {
  geo: [33.812506285591894, -117.92210698127748],
  title: "Davy Crockett's Explorer Canoes",
  type: "Boat Ride",
  year: 1956,
  heightRequirement: 0,
  description:
    "Paddle down the Rivers of America on a grand oar-venture all the way around Pirate’s Lair on Tom Sawyer Island.",
  relatedFilms: [
    { ...DAVYCROCKETTKINGOFWILD, primaryAdaptation: true },
    DAVYCROCKETTRIVERPIRATES,
  ],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/630/354/75/dam/wdpro-assets/dlr/parks-and-tickets/attractions/disneyland/davy-crocketts-explorer-canoes/davy-crocketts-explorer-canoes-00.jpg?1699632285008",
};

export const WINNIETHEPOOH: ParkRide = {
  geo: [33.812443886216954, -117.9230833053589],
  title: "The Many Adventures of Winnie the Pooh",
  type: "Dark Ride",
  year: 2003,
  heightRequirement: 0,
  description:
    "Buzz through Hundred-Acre Wood in an oversized beehive and revisit beloved songs and scenes from Winnie the Pooh.",
  relatedFilms: [
    { ...MANYADVENTURESOFWINNIETHEPOOH, primaryAdaptation: true },
    WINNIEPOOH,
  ],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/dam/wdpro-assets/dlr/parks-and-tickets/attractions/disneyland/many-adventures-of-winnie-the-pooh/many-adventures-of-winnie-the-pooh-00.jpg?1699622400593",
};

export const TIANASPLASHMOUNTAIN: ParkRide = {
  geo: [33.81212297442692, -117.92251467704774],
  title: "Tiana's Bayou Adventure",
  type: "Water Ride",
  year: 2024,
  heightRequirement: 40,
  description:
    "Get ready for an exciting, new musical journey through the Louisiana bayou with Princess Tiana—featuring daring plunges and fantastical storytelling!",
  relatedFilms: [{ ...PRINCESSANDFROG, primaryAdaptation: true }],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/630/354/75/vision-dam/digital/parks-platform/parks-global-assets/disneyland/attractions/tianas-bayou-adventure/TBA-Mama-Odie_FINAL-April-2023_copyright-16x9.jpg?2024-01-04T22:07:11+00:00",
};

export const BIGTHUNDERMOUNTAIN: ParkRide = {
  geo: [33.81267788363827, -117.92035013437274],
  title: "Big Thunder Mountain Railroad",
  type: "Roller Coaster",
  year: 1979,
  heightRequirement: 40,
  description:
    "Streak through a haunted gold mine aboard a speeding train on this thrilling coaster-style ride",
  relatedFilms: [],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/630/354/75/dam/wdpro-assets/dlr/parks-and-tickets/attractions/disneyland/big-thunder-mountain-railroad/big-thunder-mountain-railroad-00.jpg?1699631786063",
};

export const PIRATESLAIR: ParkRide = {
  geo: [33.81221880237919, -117.92123526334765],
  title: "Pirate's Lair on Tom Sawyer Island",
  type: "Other",
  year: 2007,
  heightRequirement: 0,
  description:
    "Brave an adventure all your own at this secret island hideaway inspired by the stories of Mark Twain.",
  relatedFilms: [HUCKFINN, PIRATES1, PIRATES2, PIRATES3],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/dam/wdpro-assets/dlr/parks-and-tickets/attractions/disneyland/pirates-lair-on-tom-sawyer-island/pirates-lair-on-tom-sawyer-island-00.jpg?1699621608130",
};

export const MICKEYMINNIERUNAWAYRAILWAY: ParkRide = {
  geo: [33.81545460417384, -117.91841357946399],
  title: "Mickey & Minnie's Runaway Railway",
  type: "Dark Ride",
  year: 2020,
  heightRequirement: 0,
  description:
    "Zoom off on a wild and wacky adventure through Mickey and Minnie’s madcap cartoon world.",
  relatedFilms: [{ ...MICKEYMOUSE, primaryAdaptation: true }],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/630/354/75/vision-dam/digital/parks-platform/parks-global-assets/disneyland/attractions/mickey-minnies-runaway-railway/MMRR-Exterior-16x9.jpg?2023-02-03T17:34:52+00:00",
};

export const ROGERRABBITSCARTOONSPIN: ParkRide = {
  geo: [33.81524512759786, -117.91808634996416],
  title: "Roger Rabbit's Car Toon Spin",
  type: "Dark Ride",
  year: 1994,
  heightRequirement: 0,
  description:
    "Steer a runaway taxicab through the wacky streets of Toontown as you follow the adventures of Roger Rabbit.",
  relatedFilms: [WHOFRAMEDROGERRABBIT],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/dam/wdpro-assets/dlr/parks-and-tickets/attractions/disneyland/roger-rabbits-car-toon-spin/roger-rabbits-car-toon-spin-00.jpg?1699624638998",
};

export const CHIPNDALESGADGETCOASTER: ParkRide = {
  geo: [33.815227299780474, -117.91948646306994],
  title: "Chip 'n' Dale's GADGETcoaster",
  type: "Roller Coaster",
  year: 1993,
  heightRequirement: 35,
  description:
    "Twist and turn on a speedy coaster ride built by the inventor mouse from Chip 'n' Dale Rescue Rangers.",
  relatedFilms: [
    { ...CHIPNDALERESCUERANGERS, primaryAdaptation: true },
    CHIPNDALERESCUERANGERSNEW,
  ],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/vision-dam/digital/parks-platform/parks-global-assets/disneyland/attractions/chip-n-dale-gadgetcoaster/Chip-n-Dale-GADGETcoaster-16x9.jpg?2023-04-03T16:34:27+00:00",
};

export const MRTOADSWILDRIDE: ParkRide = {
  geo: [33.81333975859283, -117.91873812675476],
  title: "Mr. Toad's Wild Ride",
  type: "Dark Ride",
  year: 1955,
  heightRequirement: 0,
  description:
    "Zig and zag in a motorcar with J. Thaddeus Toad, Esq. on a manic drive through the countryside.",
  relatedFilms: [ICHABODANDMRTOAD],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/dam/wdpro-assets/dlr/parks-and-tickets/attractions/disneyland/mr-toads-wild-ride/mr-toads-wild-ride-00.jpg?1699624781782",
};

export const PINOCCHIORIDE: ParkRide = {
  geo: [33.81327178850514, -117.91912168264392],
  title: "Pinocchio's Daring Journey",
  type: "Dark Ride",
  year: 1983,
  heightRequirement: 0,
  description:
    "Relive the classic adventures of the wooden puppet who dreamed of becoming a real boy.",
  relatedFilms: [{ ...PINOCCHIO, primaryAdaptation: true }, PINOCCHIO2022],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/dam/wdpro-assets/dlr/parks-and-tickets/attractions/disneyland/pinocchios-daring-journey/pinocchios-daring-journey-03.jpg?1699623605824",
};

export const PIXIEHOLLOW: ParkRide = {
  geo: [33.81249180002676, -117.91824191808702],
  title: "Pixie Hollow",
  type: "Entertainment",
  year: 2008,
  heightRequirement: 0,
  description:
    "Discover an enchanted forest hideaway where you’ll encounter Tinker Bell in her magical realm.",
  relatedFilms: [{ ...TINKERBELL, primaryAdaptation: true }],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/vision-dam/digital/parks-platform/parks-global-assets/disneyland/entertainment/see-tinker-bell-in-pixie-hollow/tinkerbell-pixie-hallow-16x9.jpg?2022-02-28T22:33:19+00:00",
};

export const SNOWWHITE: ParkRide = {
  geo: [33.813141419497335, -117.91910156607631],
  title: "Snow White's Enchanted Wish",
  type: "Dark Ride",
  year: 1955,
  heightRequirement: 0,
  description:
    "Embark on a magical ride into memorable scenes from Snow White and the Seven Dwarfs.",
  relatedFilms: [{ ...SNOWWHITEANDTHESEVENDWARVES, primaryAdaptation: true }],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/630/354/75/dam/disneyland/attractions/disneyland/snow-whites-enchanted-wish/snow-whites-echanted-wish-dopey-16x9.jpg?1699631846547",
};

export const STORYBOOKLANDCANALBOATS: ParkRide = {
  geo: [33.81371749301404, -117.91825130581857],
  title: "Storybook Land Canal Boats",
  type: "Boat Ride",
  year: 1955,
  heightRequirement: 0,
  description:
    "Cruise through the mouth of Monstro the Whale into a magical land of Disney movie locales—all in miniature.",
  relatedFilms: [
    ALICEINWONDERLANDFILM,
    CINDERELLA,
    PETERPAN,
    ICHABODANDMRTOAD,
    THELITTLEMERMAIDFILM,
    ALADDIN,
    FROZEN,
  ],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/630/354/75/dam/disneyland/attractions/disneyland/storybook-land-canal-boats/storybook-land-aladdin-16x9.jpg?1699631825296",
};

export const DUMBOTHEFLYINGELEPHANT: ParkRide = {
  geo: [33.813668465631736, -117.91890978813173],
  title: "Dumbo the Flying Elephant",
  type: "Carousel",
  year: 1955,
  heightRequirement: 0,
  description:
    "Soar high in the sky aboard the famous little pachyderm on a fanciful flight for all ages.",
  relatedFilms: [{ ...DUMBO, primaryAdaptation: true }, DUMBO2019],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/630/354/75/dam/disneyland/attractions/disneyland/dumbo-the-flying-elephant/dumbo-mom-kids-16x9.jpg?1699632259767",
};

export const CASEYJRCIRCUSTRAIN: ParkRide = {
  geo: [33.81375314927444, -117.91916459798814],
  title: "Casey Jr. Circus Train",
  type: "Roller Coaster",
  year: 1955,
  heightRequirement: 0,
  description:
    "Enjoy a whimsical tour through miniature versions of fairytale locales from classic Disney movies.",
  relatedFilms: [{ ...DUMBO, primaryAdaptation: true }],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/630/354/75/dam/wdpro-assets/dlr/parks-and-tickets/attractions/disneyland/casey-jr-circus-train/casey-jr-circus-train-00.jpg?1699631781913",
};

export const ALICEINWONDERLAND: ParkRide = {
  geo: [33.81329630231349, -117.9183840751648],
  title: "Alice in Wonderland",
  type: "Dark Ride",
  year: 1958,
  heightRequirement: 0,
  description:
    "Take a topsy-turvy trip into the nonsensical world of Disney’s Alice in Wonderland aboard an oversized caterpillar.",
  relatedFilms: [
    ALICEINWONDERLANDFILM,
    ALICEINWONDERLAND2010,
    ALICETHROUGHTHELOOKINGGLASS,
  ],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/dam/wdpro-assets/dlr/parks-and-tickets/attractions/disneyland/alice-in-wonderland/alice-in-wonderland-00.jpg?1692278795235",
};

export const MATTERHORNBOBSLEDS: ParkRide = {
  geo: [33.813453413372876, -117.91779801249504],
  title: "Matterhorn Bobsleds",
  type: "Roller Coaster",
  year: 1959,
  heightRequirement: 42,
  description:
    "Hurtle through a snow-capped mountain on a speeding alpine sled while avoiding the clutches of the Abominable Snowman.",
  relatedFilms: [THIRDMANONTHEMOUNTAIN],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/630/354/75/dam/wdpro-assets/dlr/parks-and-tickets/attractions/disneyland/matterhorn-bobsleds/matterhorn-bobsleds-00.jpg?1699631821208",
};

export const KINGARTHURCAROUSEL: ParkRide = {
  geo: [33.81338177084124, -117.91895872554166],
  title: "King Arthur Carrousel",
  type: "Carousel",
  year: 1955,
  heightRequirement: 0,
  description:
    "Sit astride a regal steed at this classic attraction and gently “gallop” through a whirling backdrop of color and sound.",
  relatedFilms: [],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/630/354/75/dam/wdpro-assets/dlr/parks-and-tickets/attractions/disneyland/king-arthur-carrousel/king-arthur-carrousel-00.jpg?1699631828094",
};

export const MADTEAPARTY: ParkRide = {
  geo: [33.81347648312192, -117.91827476224287],
  title: "Mad Tea Party",
  type: "Spinning Ride",
  year: 1955,
  heightRequirement: 0,
  description:
    "Pour yourself into an oversized teacup and spin through a whimsical party.",
  relatedFilms: [
    { ...ALICEINWONDERLANDFILM, primaryAdaptation: true },
    ALICEINWONDERLAND2010,
  ],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/dam/disneyland/attractions/disneyland/mad-tea-party/tea-cup-ride-father-daughters-16x9.jpg?1699623608073",
};

export const SLEEPINGBEAUTYCASTLEWALKTHROUGH: ParkRide = {
  geo: [33.81276727122998, -117.91902334489541],
  title: "Sleeping Beauty Castle Walkthrough",
  type: "Entertainment",
  year: 1957,
  heightRequirement: 0,
  description:
    "Gaze at Sleeping Beauty Castle and venture inside to relive scenes from the beloved Disney classic.",
  relatedFilms: [{ ...SLEEPINGBEAUTY, primaryAdaptation: true }],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/dam/disneyland/attractions/disneyland/sleeping-beauty-castle-walkthrough/disneyland-sleeping-beauty-castle-day-16x9.jpg?1699621847498",
};

export const STARTOURS: ParkRide = {
  geo: [33.81195917523672, -117.91789188981058],
  title: "Star Tours - The Adventures Continue",
  type: "Motion Simulator",
  year: 1987,
  heightRequirement: 40,
  description:
    "Make the jump to hyperspace aboard a 3D space flight to legendary destinations from the Star Wars saga.",
  relatedFilms: [
    STARWARSTHEPHANTOMMENACE,
    STARWARSATTACKOFTHECLONES,
    STARWARSREVENGEOFTHESITH,
    STARWARSANEWHOPE,
    STARWARSTHEEMPIRE,
    STARWARSRETURNOFTHEJEDI,
    STARWARSTHEFORCEAWAKENS,
    STARWARSTHELASTJEDI,
    STARWARSTHERISEOFSKYWALKER,
    THEMANDALORIAN,
    AHSOKA,
  ],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/630/354/75/dam/wdpro-assets/dlr/gallery/attractions/disneyland/star-tours/star-tours-gallery00.jpg?1711378982004",
};

export const ASTROBLASTERS: ParkRide = {
  geo: [33.81225445926425, -117.91797637939455],
  title: "Buzz Lightyear Astro Blasters",
  type: "Dark Ride",
  year: 2005,
  heightRequirement: 0,
  description:
    "Calling all Space Rangers! Take aim and fire your laser to defeat Zurg during a thrilling space battle.",
  relatedFilms: [{ ...TOYSTORY, primaryAdaptation: true }, TOYSTORY2],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/630/354/75/dam/wdpro-assets/dlr/parks-and-tickets/attractions/disneyland/buzz-lightyear-astro-blaster/buzz-lightyear-astro-blasters-00.jpg?1699631783319",
};

export const FINDINGNEMO: ParkRide = {
  geo: [33.81275699690735, -117.9169584810734],
  title: "Finding Nemo Submarine Voyage",
  type: "Boat Ride",
  year: 2007,
  heightRequirement: 0,
  description:
    "Journey into the charming world of Disney and Pixar’s Finding Nemo on a gentle undersea expedition.",
  relatedFilms: [{ ...FINDINGNEMOFILM, primaryAdaptation: true }, FINDINGDORY],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/dam/wdpro-assets/dlr/parks-and-tickets/attractions/disneyland/finding-nemo-submarine-voyage/finding-nemo-submarine-voyage-00.jpg?1699624467035",
};

export const SMUGGLERSRUN: ParkRide = {
  geo: [33.814915312374744, -117.92143642902376],
  title: "Millennium Falcon: Smugglers Run",
  type: "Motion Simulator",
  year: 2019,
  heightRequirement: 38,
  description:
    "Fly the Millennium Falcon on a thrilling interactive smuggling mission.",
  relatedFilms: [
    STARWARSANEWHOPE,
    STARWARSTHEEMPIRE,
    STARWARSRETURNOFTHEJEDI,
    STARWARSTHEFORCEAWAKENS,
    STARWARSTHELASTJEDI,
    { ...STARWARSTHERISEOFSKYWALKER, primaryAdaptation: true },
  ],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/dam/disneyland/attractions/disneyland/millennium-falcon-smugglers-run/smugglers-run-timeplapse.mp4?1699632287608",
};

export const RISEOFTHERESISTANCE: ParkRide = {
  geo: [33.81410859680764, -117.92326703667642],
  title: "Star Wars: Rise of the Resistance",
  type: "Dark Ride",
  year: 2019,
  heightRequirement: 40,
  description:
    "Join the Resistance in an epic battle against the First Order on this exciting ride.",
  relatedFilms: [
    STARWARSTHEFORCEAWAKENS,
    STARWARSTHELASTJEDI,
    { ...STARWARSTHERISEOFSKYWALKER, primaryAdaptation: true },
  ],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/630/354/75/dam/disneyland/home/destination/rise-of-the-resisstance-interior-16x9.jpg?1699631833634",
};

export const MONSTERSINC: ParkRide = {
  geo: [33.808242966483476, -117.91727900505067],
  title: "Monsters, Inc. Mike & Sulley to the Rescue!",
  type: "Dark Ride",
  year: 2006,
  heightRequirement: 0,
  description:
    "Zip through scenes from the Disney and Pixar film Monsters, Inc. on a wild taxi ride with familiar scare-acters.",
  relatedFilms: [
    { ...MONSTERSINCFILM, primaryAdaptation: true },
    MONSTERSUNIVERSITY,
  ],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/dam/wdpro-assets/dlr/parks-and-tickets/attractions/disney-california-adventure/monsters-inc/monsters-inc-00.jpg?1699621744103",
};

export const TURTLETALK: ParkRide = {
  geo: [33.80728909496564, -117.91783824563028],
  title: "Turtle Talk with Crush",
  type: "Entertainment",
  year: 2005,
  heightRequirement: 0,
  description:
    "Chat live with Crush, the totally tubular sea turtle from Finding Nemo and Finding Dory.",
  relatedFilms: [{ ...FINDINGNEMOFILM, primaryAdaptation: true }, FINDINGDORY],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/vision-dam/digital/parks-platform/parks-global-assets/disneyland/attractions/turtle-talk/turtle-talk-with-crush-00-16x9.jpg?2024-04-12T19:22:54+00:00",
};

export const GUARDIANSOFTHEGALAXY: ParkRide = {
  geo: [33.8067720386705, -117.91680827736856],
  title: "Guardians of the Galaxy – Mission: Breakout!",
  type: "Dark Ride",
  year: 2017,
  heightRequirement: 40,
  description:
    "Join Rocket on a thrilling mission to rescue the Guardians of the Galaxy from the Collector’s fortress.",
  relatedFilms: [
    { ...GUARDIANSOFTHEGALAXYFILM, primaryAdaptation: true },
    GUARDIANSOFTHEGALAXYVOL2,
    AVENGERSINFINITYWAR,
    AVENGERSENDGAME,
    GUARDIANSOFTHEGALAXYVOL3,
  ],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/dam/disneyland/attractions/disney-california-adventure/guardians-galaxy-mission-breakout/cinemagraph/mission-breadkout-1280x720.mp4?1699631771077",
};

export const INCREDICOASTER: ParkRide = {
  geo: [33.80458342957789, -117.92045071721078],
  title: "Incredicoaster",
  type: "Roller Coaster",
  year: 2018,
  heightRequirement: 48,
  description:
    "Join the Incredibles in a mad dash to catch Jack-Jack as he wreaks havoc throughout this high-speed chase!",
  relatedFilms: [
    { ...THEINCREDIBLES, primaryAdaptation: true },
    THEINCREDIBLES2,
  ],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/630/354/75/dam/disneyland/destinations/disney-california-adventure/pixar-pier/incredicoaster-guests-16x9.jpg?1699631823973",
};

export const LUIGISROLICKINROADSTERS: ParkRide = {
  geo: [33.80549275664996, -117.91837066411975],
  title: "Luigi's Rollickin' Roadsters",
  type: "Spinning Ride",
  year: 2016,
  heightRequirement: 32,
  description:
    "Climb aboard a colorful car that “dances” its way across Luigi’s lot to tire-tapping Italian tunes!",
  relatedFilms: [{ ...CARS, primaryAdaptation: true }],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/dam/wdpro-assets/dlr/parks-and-tickets/attractions/disney-california-adventure/luigis-rollickin-roadsters/luigis-rollickin-roadsters-00.jpg?1699623437166",
};

export const MATERSJUNKYARDJAMBOREE: ParkRide = {
  geo: [33.80648230748401, -117.91913911700249],
  title: "Mater's Junkyard Jamboree",
  type: "Spinning Ride",
  year: 2012,
  heightRequirement: 32,
  description:
    "Ride along aboard a tractor during a tow-tappin’ hoedown hosted by Mater from the Disney and Pixar film Cars.",
  relatedFilms: [{ ...CARS, primaryAdaptation: true }],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/dam/wdpro-assets/dlr/parks-and-tickets/attractions/disney-california-adventure/maters-junkyard-jamboree/maters-junkyard-jamboree-00.jpg?1699621861558",
};

export const RADIATORSPRINGSRACERS: ParkRide = {
  geo: [33.804634855396124, -117.91903585195544],
  title: "Radiator Springs Racers",
  type: "Dark Ride",
  year: 2012,
  heightRequirement: 40,
  description:
    "Start your engines! Zoom through the desert landscape of Cars Land, inspired by the Disney and Pixar movie Cars.",
  relatedFilms: [{ ...CARS, primaryAdaptation: true }, CARS2, CARS3],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/630/354/75/dam/wdpro-assets/dlr/gallery/attractions/disney-california-adventure/radiator-springs-racers/radiator-springs-racers-gallery00.jpg?1699631816658",
};

export const JESSIESCRITTERCAROUSEL: ParkRide = {
  geo: [33.80453439696121, -117.92117357254028],
  title: "Jessie's Critter Carousel",
  type: "Carousel",
  year: 2019,
  heightRequirement: 0,
  description:
    "Saddle up for a whimsical spin on Jessie’s favorite critter pals from her wild west adventures—yee-haw!",
  relatedFilms: [{ ...TOYSTORY2, primaryAdaptation: true }],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/dam/disneyland/attractions/disney-california-adventure/jessies-critter-carousel/jessies-critter-carousel-16x9_1.jpg?1699622446286",
};

export const INSIDEOUTEMOTIONALWHIRLWIND: ParkRide = {
  geo: [33.80523868094084, -117.9228848218918],
  title: "Inside Out Emotional Whirlwind",
  type: "Spinning Ride",
  year: 2019,
  heightRequirement: 0,
  description:
    "Tap into Riley’s Emotions when you take a sense-sational spin around the whimsical world inside her mind’s Headquarters.",
  relatedFilms: [{ ...INSIDEOUT, primaryAdaptation: true }],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/dam/disneyland/attractions/disney-california-adventure/emotional-whirlwind/emotional-whirlwind-inside-out-16x9.jpg?1699622874475",
};

export const PIXARPALAROUND: ParkRide = {
  geo: [33.805136158949864, -117.92218476533891],
  title: "Pixar Pal-A-Round",
  type: "Carousel",
  year: 2018,
  heightRequirement: 0,
  description:
    "Climb aboard for an exhilarating Ferris wheel adventure that swoops, swings, and slides—high above Pixar Pier.",
  relatedFilms: [],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/dam/disneyland/destinations/disney-california-adventure/pixar-pier/pixar-pal-around-night-16x9.jpg?1699622634294",
};

export const TOYSTORYMIDWAYMANIA: ParkRide = {
  geo: [33.80463691967316, -117.92172342538836],
  title: "Toy Story Midway Mania!",
  type: "Dark Ride",
  year: 2008,
  heightRequirement: 0,
  description:
    "Zip through an exhilarating 4D midway-style game starring Toy Story characters—and blast away!",
  relatedFilms: [TOYSTORY, TOYSTORY2, TOYSTORY3, TOYSTORY4],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/dam/wdpro-assets/dlr/parks-and-tickets/attractions/disney-california-adventure/toy-story-mania/toy-story-mania-00.jpg?1699624320733",
};

export const GOLDENZEPHYR: ParkRide = {
  geo: [33.805958560157315, -117.92225986719133],
  title: "Golden Zephyr",
  type: "Spinning Ride",
  year: 2001,
  heightRequirement: 0,
  description:
    "Soar high in the sky in a gleaming retro spaceship inspired by science fiction stories of the 1920s.",
  relatedFilms: [],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/dam/wdpro-assets/dlr/parks-and-tickets/attractions/disney-california-adventure/golden-zephyr/golden-zephyr-00.jpg?1699624418349",
};

export const GOOFYSSKYSCHOOL: ParkRide = {
  geo: [33.80641544630167, -117.92287677526477],
  title: "Goofy's Sky School",
  type: "Roller Coaster",
  year: 2011,
  heightRequirement: 42,
  description:
    "Soar high—and learn to fly the Goofy way—on this coaster-style attraction.",
  relatedFilms: [AGOOFYMOVIE, ANEXTREMELYGOOFYMOVIE],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/dam/wdpro-assets/dlr/parks-and-tickets/attractions/disney-california-adventure/goofys-sky-school/goofys-sky-school-00.jpg?1699621731451",
};

export const JUMPINJELLYFISH: ParkRide = {
  geo: [33.80599867708718, -117.92269170284273],
  title: "Jumpin' Jellyfish",
  type: "Carousel",
  year: 2001,
  heightRequirement: 40,
  description:
    "Float high into the sky above Paradise Bay aboard a colorful jellyfish before gently sinking back down to dry land.",
  relatedFilms: [],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/dam/wdpro-assets/dlr/parks-and-tickets/attractions/disney-california-adventure/jumpin-jellyfish/jumpin-jellyfish-00.jpg?1699623360928",
};

export const THELITTLEMERMAID: ParkRide = {
  geo: [33.80618366046501, -117.92143106460573],
  title: "The Little Mermaid: Ariel's Undersea Adventure",
  type: "Dark Ride",
  year: 2011,
  heightRequirement: 0,
  description:
    "Journey under the sea for a whimsical musical adventure awash with scenes from the animated Disney classic.",
  relatedFilms: [
    { ...THELITTLEMERMAIDFILM, primaryAdaptation: true },
    THELITTLEMERMAID2023,
  ],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/630/354/75/dam/wdpro-assets/dlr/gallery/attractions/disney-california-adventure/little-mermaid-ariels-undersea-adventure/little-mermaid-ariels-undersea-adventure-gallery01.jpg?1700057733807",
};

export const SILLYSYMPHONY: ParkRide = {
  geo: [33.80564653894951, -117.92268365621568],
  title: "Silly Symphony Swings",
  type: "Carousel",
  year: 2001,
  heightRequirement: 40,
  description:
    "Get swept off your feet and into the air as you wheel above it all in graceful, soaring circles.",
  relatedFilms: [],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/dam/wdpro-assets/dlr/parks-and-tickets/attractions/disney-california-adventure/silly-symphony-swings/silly-symphony-swings-00.jpg?1699621602267",
};

export const WEBSLINGERS: ParkRide = {
  geo: [33.80667397591721, -117.91834920644763],
  title: "WEB SLINGERS: A Spider-Man Adventure",
  type: "Dark Ride",
  year: 2021,
  heightRequirement: 0,
  description:
    "Wrangle rogue Spider-Bots run amok during this mayhem-filled mission at Avengers Campus!",
  relatedFilms: [
    { ...SPIDERMANHOMECOMING, primaryAdaptation: true },
    SPIDERMANFARFROMHOME,
  ],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/630/354/75/dam/disneyland/attractions/disney-california-adventure/web-slingers-spider-man-adventure/webslingers-night-16x9.jpg?1699631837919",
};

export const PYMSTESTKITCHEN: ParkRide = {
  geo: [33.80687121565894, -117.91775643825532],
  title: "Pym's Test Kitchen",
  type: "Dining",
  year: 2021,
  heightRequirement: 0,
  description:
    "Refuel with Ant-Man and The Wasp’s size-defying fare at this epic eatery at Avengers Campus!",
  relatedFilms: [
    ANTMAN,
    { ...ANTMANANDTHEWASP, primaryAdaptation: true },
    AVENGERSENDGAME,
    ANTMANANDTHEWASPQUANTUMANIA,
  ],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/dam/disneyland/dining/disney-california-adventure/pym-test-kitchen/pyms-kitchen-16x9.jpg?1706062171480",
};

export const AVENGERSHQ: ParkRide = {
  geo: [33.8065045945332, -117.91756331920625],
  title: "Avenger's Headquarters",
  type: "Entertainment",
  year: 2021,
  heightRequirement: 0,
  description:
    "Encounter some of Earth’s Mightiest Heroes and watch them spring into action to defend the Avengers command center!",
  relatedFilms: [
    THEAVENGERS,
    AVENGERSAGEOFULTRON,
    AVENGERSINFINITYWAR,
    AVENGERSENDGAME,
  ],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/dam/disneyland/destinations/disney-california-adventure/avengers-campus/campus-night-16x9.jpg?1693575397728",
};

export const SANFRANSOKYOSQUARE: ParkRide = {
  geo: [33.8057290018079, -117.91999742388728],
  title: "San Fransokyo Square",
  type: "Dining",
  year: 2023,
  heightRequirement: 0,
  description:
    "Step into a vibrant, diverse locale full of neighborhood restaurants and small businesses—home to Big Hero 6!",
  relatedFilms: [{ ...BIGHERO6, primaryAdaptation: true }],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1349/464/75/vision-dam/digital/parks-platform/parks-global-assets/disneyland/attractions/san-fransokyo/631e667665a70-D23Expo2022_DLR_PacificWharf-16x9.jpg?2023-06-20T21:07:18+00:00",
};

export const ENCHANTEDTIKIROOM: ParkRide = {
  geo: [33.81164494734088, -117.91957899928093],
  title: "Enchanted Tiki Room",
  type: "Entertainment",
  year: 1963,
  heightRequirement: 0,
  description:
    "Tropical birds, tiki gods and colorful flowers come to life in a swinging South Seas musical celebration.",
  relatedFilms: [],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/dam/disneyland/attractions/disneyland/enchanted-tiki-room/cinemagraph/enchanted-tiki-room-1280x720.mp4?1699632243502",
};

export const ITSASMALLWORLD: ParkRide = {
  geo: [33.8146534649238, -117.91781544685365],
  title: "it's a small world",
  type: "Boat Ride",
  year: 1966,
  heightRequirement: 0,
  description:
    "Board a whimsical boat ride for a song-filled journey around the globe—this cherubic chorus is pure joy!",
  relatedFilms: [],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/dam/disneyland/attractions/disneyland/its-a-small-world/small-world-exterior-16x9.jpg?1705683946530",
};

export const GOOFYSHOWTOYARD: ParkRide = {
  geo: [33.81512367551835, -117.91873946785928],
  title: "Goofy's How to Play Yard",
  type: "Entertainment",
  year: 2008,
  heightRequirement: 0,
  description:
    "Scamper around a haywire homestead that could only have been designed by Goofy himself.",
  relatedFilms: [AGOOFYMOVIE, ANEXTREMELYGOOFYMOVIE],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/vision-dam/digital/parks-platform/parks-global-assets/disneyland/attractions/goofys-how-to-play-yard/Goofys-How-to-Play-Yard-16x9.jpg?2023-04-03T16:37:21+00:00",
};

export const DONALDSDUCKPOND: ParkRide = {
  geo: [33.81518384446004, -117.9190640151501],
  title: "Donald's Duck Pond",
  type: "Entertainment",
  year: 1993,
  heightRequirement: 0,
  description:
    "Make a splash with some birds of a feather in a water play area overflowing with fun.",
  relatedFilms: [DUCKTALES, DUCKTALESNEW],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/vision-dam/digital/parks-platform/parks-global-assets/disneyland/attractions/donalds-duck-pond/Donalds-Duck-Pond-16x9.jpg?2023-04-03T16:36:57+00:00",
};

//

export const AUTOPIA: ParkRide = {
  geo: [33.81261771293393, -117.91631340980531],
  title: "Autopia",
  type: "Other",
  year: 1955,
  heightRequirement: 32,
  description:
    "Put the pedal to the metal on an unforgettable road trip along a miniature motorway.",
  relatedFilms: [],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/dam/wdpro-assets/dlr/parks-and-tickets/attractions/disneyland/autopia/autopia-09.jpg?1692278574180",
};

export const ALIENPIZZAPLANET: ParkRide = {
  geo: [33.81156360447056, -117.91676402091981],
  title: "Alien Pizza Planet",
  type: "Dining",
  year: 2018,
  heightRequirement: 0,
  description:
    "Fuel up with out-of-this-world fast fare like breadsticks, pizza, and salad—it’s an Italiano meal you can’t refuse!",
  relatedFilms: [{ ...TOYSTORY, primaryAdaptation: true }],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/dam/disneyland/destinations/disneyland/tomorrowland/disneyland-pizza-port-pepperoni-16x9.jpg?1701203854888",
};

export const SPACEMOUNTAIN: ParkRide = {
  geo: [33.81098640215548, -117.91750162839891],
  title: "Space Mountain",
  type: "Roller Coaster",
  year: 1977,
  heightRequirement: 40,
  description:
    "Race through the cosmos in the dark to the edge of the galaxy and back on a thrilling roller-coaster ride",
  relatedFilms: [],
  background:
    "https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/1600/900/75/dam/wdpro-assets/dlr/parks-and-tickets/attractions/disneyland/space-mountain/space-mountain-00.jpg?1712319418612",
};
