import React from "react";
import { RelatedFilm } from "../types";
import { Sparkle } from "@phosphor-icons/react";

const ratingLogoMap = new Map<RelatedFilm["rating"], string>([
  [
    "G",
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/7843B7AF26846E842BA3EDD8628471E6435C5DAC9F89FF5307D345EAADCAE6F5/compose?format=webp&height=40",
  ],
  [
    "PG",
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/617B8E26BE83893584472159F50AA8D4C7DDD89FA14EFDCD4FE0BCD246880EF5/compose?format=webp&height=40",
  ],
  [
    "PG-13",
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/DED60150F7200E86F784895EDC91820E0016AF0DFAA261D4F9A050D2632B6E64/compose?format=webp&height=40",
  ],
  [
    "R",
    "https://disney.images.edge.bamgrid.com/ripcut-delivery/v1/variant/disney/079985A354188094C63487F79F1306375EDFDF1E91BA7ED67E10A5C41955B1B5/compose?format=webp&height=40",
  ],
]);

const FilmCard = (props: { film: RelatedFilm }) => {
  const { film } = props;

  return (
    <a
      href={film.link}
      className="group/filmcard w-full relative aspect-video rounded overflow-hidden shadow mb-4 flex gap-1 flex-col items-center justify-center border border-white/5"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${film.background}"))`,
      }}
    >
      {film.primaryAdaptation && (
        <div className="absolute right-3 top-3 z-10">
          <Sparkle size={20} />
        </div>
      )}

      <img
        src={film.logo}
        alt={`${film.title} logo`}
        className="object-contain z-10 h-32 w-60 group-hover/filmcard:opacity-0 transition-opacity"
      />
      <h2 className="text-xs text-white mb-1 flex items-center z-10 opacity-75 group-hover/filmcard:opacity-0 transition-opacity">
        {ratingLogoMap.has(film.rating) ? (
          <img
            className="h-3 mr-0.5"
            src={ratingLogoMap.get(film.rating)}
            alt={`${film.rating} rating logo`}
          />
        ) : (
          film.rating
        )}
        &nbsp;&nbsp;{film.genre}&nbsp;&nbsp;{film.year}&nbsp;&nbsp;
        {film.runtime}
      </h2>
      <div className="py-4 px-5 uppercase rounded-full bg-white absolute opacity-0 transition-opacity group-hover/filmcard:opacity-100 z-10">
        <h3 className="text-sm font-bold text-gray-900 tracking-widest">
          Watch on Disney+
        </h3>
      </div>
      <img
        src={film.background}
        alt={`${film.title} background`}
        className="inset-0 absolute object-cover opacity-50 z-0 group-hover/filmcard:opacity-100 transition-opacity"
      />
    </a>
  );
};

export default FilmCard;
